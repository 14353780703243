import React from 'react';
import { Layout } from '@components/global/Layout';
import { RouteEnum } from '@model/route.enum';

interface Props {
  title: string;
  description: string;
  children: React.ReactNode;
}

export const SimplePage: React.FC<Props> = (props) => {
  const { title, description, children } = props;
  return (
    <Layout
      title={title}
      navigationItems={[{ title: 'Zur Startseite', to: RouteEnum.INDEX }]}
    >
      <div className="flex flex-col p-32 mb-16">
        <p>{description}</p>
        {children}
      </div>
    </Layout>
  );
};
